'use client';

import { ThemeProvider } from 'styled-components';
import { lightTheme } from '../styles';

export default function ThemeClient({
  children,
}: {
  children: React.ReactNode;
}) {
  return <ThemeProvider theme={lightTheme}>{children}</ThemeProvider>;
}
