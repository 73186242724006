'use client';

import Script from 'next/script';
import { WEBSITE_ENV } from './helpers';

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const pageview = () => {
  window.fbq('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const fbqEvent = (name: any, options = {}) => {
  window.fbq('track', name, options);
};

export const FacebookPixelScript: any = () => {
  return (
    <>
      {WEBSITE_ENV === 'production' && (
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', 210552255076781);
            fbq('track', 'PageView');
`,
          }}
        />
      )}
    </>
  );
};

export const FacebookPixelNoScriptImg = () => {
  return (
    <>
      {WEBSITE_ENV === 'production' && (
        <noscript>
          <img
            height="1"
            width="1"
            src={`https://www.facebook.com/tr?id=210552255076781&ev=PageView&noscript=1`}
          />
        </noscript>
      )}
    </>
  );
};
