import(/* webpackMode: "eager" */ "/app/apps/vgrubs/app/clientRootLayout.tsx");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/app/globals.css");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/app/registry.tsx");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/components/footer/footer.js");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/components/navigation/navigation.tsx");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/components/toasts/toastsStyled.ts");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/helpers/chatWidget.tsx");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/helpers/facebookPixel.tsx");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/helpers/gtm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/helpers/helpers.js");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/styles/styles.ts");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/styles/text.ts");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/styles/theme.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.min.css")